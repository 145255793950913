import clsx from 'clsx';
import { FC } from 'react';

type CardProps = { variant?: 'small' | 'large'; className?: string };
const Card: FC<CardProps> = ({ variant = 'small', className, children }) => (
  <div
    className={clsx(
      'rounded px-4 shadow-md',
      variant === 'small' ? 'py-3' : 'pt-4 pb-6',
      className,
    )}
  >
    {children}
  </div>
);

export default Card;
